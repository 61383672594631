.full-width-video {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
}

.text-block {
    margin: 2rem 0 4rem 0
}

h6 {
    margin: 4rem 0 1rem 0
}