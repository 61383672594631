.language-switcher {
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-left: auto;
    /* Push the switcher to the right */
}

.language-button {
    background: none;
    border: none;
    color: #f7f9f9;
    cursor: pointer;
    font-size: 1rem;
}

.language-button:hover {
    color: #000;
    background: none;
}

.language-button.active {
    color: #e6af2e;
    background: none;
    /* Highlight the active language */
}