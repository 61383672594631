@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/fa-brands-400.eot");
  src: url("../fonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fa-brands-400.woff2") format("woff2"),
    url("../fonts/fa-brands-400.woff") format("woff"),
    url("../fonts/fa-brands-400.ttf") format("truetype"),
    url("../fonts/fa-brands-400.svg#fontawesome") format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}
