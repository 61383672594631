/* Add spacing between each step */
.step {
    margin: 3rem 0 0 0;
    /* Adds spacing between steps */
}

/* Title for each step */
.step-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    /* Adds spacing below the title */
}

/* Images for each step */
.responsive-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    display: block;
    margin: 1rem auto;
    /* Centers the image and adds spacing */
}

.responsive-wallet-image {
    width: 50%;
    max-width: 500px;
    height: 50%;
    display: block;
    margin: 1rem auto;
}


/* Text styling */
.step p {
    font-size: 1rem;
    line-height: 1.6;
    margin-top: 1rem;
}