@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/fa-regular-400.eot");
  src: url("../fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fa-regular-400.woff2") format("woff2"),
    url("../fonts/fa-regular-400.woff") format("woff"),
    url("../fonts/fa-regular-400.ttf") format("truetype"),
    url("../fonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
