.input-field {
  margin-bottom: 20px;
}

button:focus {
  background-color: #e6af2e;
}

.react-datepicker__day--today {
  background-color: #e6af2e !important;
}

.react-datepicker__day--selected {
  background-color: #3b8ea5 !important;
}

.btn {
  background-color: #263238;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #37474f;
}

.result {
  margin-top: 20px;
}

.error {
  color: red;
  margin-top: 20px;
}

.modal-overlay {
  display: none !important;
}

.select-dropdown {
  z-index: 1003 !important;
}