.active .collapsible-header {
  background-color: #346ca5 !important;
  color: #fff;
}

.active span.badge {
  color: #fff;
}

.bold {
  font-weight: bold;
}
.stepper-container {
  position: relative;
}

.stepper-button {
  position: fixed;
  top: 40%;
  transform: translateY(-50%);
}

.btn-floating.btn-large i {
  background-color: #e6af2e;
}

.btn-floating.btn-large:disabled i {
  background-color: #dfdfdf !important;
  color: #9f9f9f !important;
}


.stepper-nav-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.stepper-nav-buttons button {
  margin: 0 10px;
}