.card {
  box-shadow: none !important;
  padding: 0;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.card-content {
  padding: 10px;
}

.card-title {
  font-size: 24px;
  font-weight: bold;
}

.card-action a {
  margin-right: 20px; 
}

.card-action i.material-icons {
  vertical-align: middle;
  margin-right: 5px;
}



.card-action .row {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-bottom: 0;
}

.icon-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-description i.material-icons {
  font-size: 24px;
  margin-bottom: 5px;
  flex-direction: column;
  align-items: center; 
}

.icon-description span {
  font-size: 12px;
}