body {
  font-family: 'futura-pt', sans-serif;
}

h1 {
  color: #e6af2e; /* Your primary color */
}

h1, h2, h3, h4, h5 {
  font-family: 'paralucent', 'Roboto', 'sans-serif';
  font-weight: 400; 
}

h1 {
  font-size: 2rem !important;
  line-height: 110%;
  margin: 2.1rem 0 1.68rem 0;
}

h2 {
  font-size: 1.8rem !important;
  line-height: 110%;
  margin: 1.78rem 0 1.424rem 0;
}

h3 {
  font-size: 1.6rem;
  line-height: 110%;
  margin: 1.46rem 0 1.168rem 0;
}

h4 {
  font-size: 1.4rem;
  line-height: 110%;
  margin: 1.14rem 0 0.912rem 0;
}

h5 {
  font-size: 1.2rem;
  line-height: 110%;
  margin: 0.82rem 0 0.656rem 0;
}

h6 {
  font-size: 1.1rem;
  line-height: 110%;
  margin: 0.5rem 0 0.4rem 0;
}


p {
  line-height: 1.5rem;
}

.price-widget .ccc-widget:after {
  content: "";
  display: table;
  clear: both;
}

.collapsible.popout li {
  margin: 0;
}

#topnavbar .brand-logo {
  outline: none;
}

#topnavbar .navbar-logo {
  margin-bottom: 5px;
  width: 130px;
}

nav {
  background-color: rgba(236, 239, 241, 0.05);
}

nav #logo-container {
  color: #fff;
}

nav li a {
  color: #cfd8dc;
}

a .unstyled {
  text-decoration: none;
}

p {
  line-height: 1.5rem;
}

.text-primary {
  color: #e6af2e !important;
}

.secondary-text {
  color: #212121 !important;
}

.text-primary-light {
  /*color: #3b8ea5;*/
  color: #346ca5;
}

.button-collapse {
  color: #f7f9f9;
}

.icon-block {
  padding: 0 15px;
}

.icon-block .material-icons {
  font-size: inherit;
}


footer.page-footer {
  margin: 2em 0 0;
}

.page-footer .footer-copyright {
  padding: 2em;
}

#topnavbar {
  /*position: static;*/
  background-color: #263238;
}

#index-navbar {
  position: absolute;
  top: 0;
}

#index-banner .primary-overlay {
  background-color: rgba(38, 50, 56, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.map-location {
  height: 400px;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 25px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

.row:last-child {
  margin-bottom: 0;
}

.home-grey-section {
  padding: 2em 0;
  background-color: #eceff1;
}

.home-emphasis {
  font-size: 1.2rem;
  line-height: 1.5em;
  padding: 2em;
}

.home-regular {
  padding: 2em;
}

.home-banner {
  height: 220px;
  position: relative;
  background-image: url("../public/assets/images/website-background-couple.d8fc393592cadc2145ad.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #323232;
  font-size: 1.1rem;
}

@media (min-width: 900px) {
  .home-banner {
    height: 500px;
    font-size: 1.8rem;
  }
}

@media (min-width: 1500px) {
  .home-banner {
    background-size: 1500px auto;
  }
}

.home-banner .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-banner .content .content-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0 15px;
  margin: 15px;
  font-family: "paralucent", "Roboto", "sans-serif";
  font-weight: 400;
  text-align: center;
  padding: 0.5em;
}

.home-banner .content .content-item-images {
  padding: 10px;
  margin: 0.5em 0 0;
}

.content-item-image {
  width: 2.5em;
  height: 2.5em;
  margin: 0 0.5em;
}

.social-networks {
  margin: 25px 0 15px;
}

.status-indicator {
  display: inline-block;
  vertical-align: center;
  margin-left: 0.5em;
}

.status-indicator::before {
  display: inline-block;
  vertical-align: center;
  content: "";
  width: 0.7em;
  height: 0.7em;
  border-radius: 50%;
  margin-right: 0.2em;
}

.status-indicator.online {
  color: green;
}

.status-indicator.online::before {
  background-color: green;
}

.status-indicator.offline {
  color: red;
}

.status-indicator.offline::before {
  background-color: red;
}

.contactscontainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 1px 0px;
  grid-auto-flow: column;
  align-content: stretch;
  /* grid-template-areas:
    "espaco suporte imprensa"; */
  width: 100%;
  height: 100%;
  align-items: start;
}

@media (min-width: 900px) {
  .contactscontainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
  }
}

/* .espaco { grid-area: espaco; }

.suporte { grid-area: suporte; }

.imprensa { grid-area: imprensa; } */

.map-full-width {
  width: 100%;
  height: 500px;
  margin: 40px 0 30px;
}

.location-item {
  font-family: "futura-pt", "Roboto", "sans-serif";
  padding: 0.5em;
  font-size: 0.9rem;
}

.location-item p {
  margin: 0.4em 0;
}

.location-item p:first-child {
  margin-top: 0;
}

.location-item p:last-child {
  margin-bottom: 0;
}

.location-text-horizontal {
  padding: 1em 1em 0;
  display: grid;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.location-text-horizontal>* {
  padding: 0 1em 2em;
  text-align: center;
  flex-basis: auto;
  flex-basis: 20em;
}