.input-field input[type="text"]:focus + label {
  color: #e6af2e !important; /* Your primary color */
}

.input-field input[type="text"]:focus {
  border-bottom: 1px solid #e6af2e !important; /* Your primary color */
  box-shadow: 0 1px 0 0 #e6af2e !important; /* Your primary color */
}

.collapsible-header {
  display: flex;
  align-items: center;
}

.collapsible-header i.material-icons {
  margin-right: 10px;
}